import { useAuthStore } from '@/auth';
import { createRouter, createWebHashHistory } from 'vue-router'

const  routes = [
  {
    path: '/',
    component: () => import('@/layouts/default.vue'),
    children: [
      {
        name: 'Home',
        path: '',
        component: () => import('@/components/Home.vue')
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/components/Login.vue')
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import('@/components/Users.vue')
      },
      {
        path: '/users/:id',
        name: 'User',
        component: () => import('@/components/User.vue')
      },
      {
        path: '/testdata',
        name: 'Test Data',
        component: () => import('@/components/TestData.vue')
      },
      {
        path: '/epg',
        name: 'EPG',
        component: () => import('@/components/EPG.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, _from, next) => {
  const authStore = useAuthStore();

  authStore.getUser((response: any) => {
    if (!response) {
      router.push('/login')
    } else {
      if (to.path == '/login') {
        router.push('/')
      } else {
        router.push(to.path)
      }
    }
  })

  next();
});

export default router;
