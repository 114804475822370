import filter from 'lodash/filter'
import axios from 'axios'
import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useAuthStore = defineStore('authStore', () => {
  const loggedIn = ref(false);
  const loggedInUser = ref({});

  const getUser = (callback: Function) => {
    axios.get('/api/myself').then(response => {
      loggedIn.value = true
      loggedInUser.value = response.data
      if (typeof callback === 'function') {
        callback(loggedInUser.value)
      }
    }, () => {
      loggedIn.value = false
      if (typeof callback === 'function') {
        callback(false)
      }
    })
  }

  const logout = () => {
    axios.get('/api/logout').then(() => {
      loggedIn.value = false
      loggedInUser.value = {}
      //window.localStorage.removeItem("Authorization");
      location.reload()
    }, response => {
      if (response.body.code === "Unauthorized") {
        loggedIn.value = false
        //window.localStorage.removeItem("Authorization");
        location.reload()
      }
    })
  }

  const authenticate = (callback: Function, username: string, password: string, domain: string) => {
    axios.post('/api/auth', {username, password, domain}).then(() => {
      loggedIn.value = true
      //localStorage.setItem("Authorization", response.body.token)
      if (typeof callback === 'function') {
        callback(loggedIn.value)
      }
    }, () => {
      loggedIn.value = false
      if (typeof callback === 'function') {
        callback(loggedIn.value)
      }
    })
  }

  const isAdmin = () => {
    var isAdmin = false
    filter((loggedInUser as any).value.roles, function (value) {
      if (value === 'admin') {
        isAdmin = true
      }
    })
    return loggedIn.value && isAdmin
  }

  const isLoggedIn = () => {
    return loggedIn.value
  }

  return {
    isLoggedIn,
    isAdmin,
    authenticate,
    logout,
    getUser,
  }
});
