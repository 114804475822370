import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
  theme: {
    themes: {
      dark: {
        colors: {
          primary: "#E9BB3F",
          secondary: "#b0bec5",
          accent: "#8c9eff",
          error: "#b71c1c"
        }
      },
    },
    defaultTheme: 'dark'
  },
  components,
  directives
});

export default vuetify;