import { createApp } from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VuetifyUseDialog from 'vuetify-use-dialog'
import pinia from './plugins/pinia'

const app = createApp(App);

app.use(vuetify)
app.use(router)
app.use(VuetifyUseDialog)
app.use(pinia)

app.mount('#app');
